@import "../tailwind-config/tailwind.styles.css";
@import "../tailwind-config/fonts.module.css";

@import "animate.css";

html,
body {
  height: 100%;
  max-height: -webkit-fill-available;
  background: #ffffff;
  color: #150038;
  scroll-behavior: auto;
}

div#__next {
  height: 100%;
}

.mapouter {
  position: relative;
  text-align: right;
}

.gmap_canvas {
  overflow: hidden;
  background: none !important;
}

.yellow-shadow {
  text-shadow: 3px 3px 6px rgb(254, 248, 0), 0 0 5px rgb(254, 248, 0);
}

.crazy-font {
  font-weight: 700;
  font-size: 3.5rem;
  line-height: unset;
  text-align: center;
  color: #f9f1cc;
  text-shadow: 3px 3px 0px #ffb650, 6px 6px 0px #cd0000;
  position: relative;
  top: -80px;
  transform: rotate(-5deg);
}

.crazy-font-move-up {
  transform: translateX(30px) translateY(-160px);
}

@keyframes slideInRightFade {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOutRightFade {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}

.slide-in-right-fade {
  animation: slideInRightFade 0.5s forwards;
}

.slide-out-right-fade {
  animation: slideOutRightFade 0.5s forwards;
}

@keyframes slideUp {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-100%);
    opacity: 0;
  }
}

.banner-slide-up {
  animation: slideUp 0.5s forwards; /* Adjust the duration as needed */
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.banner-slide-down {
  animation: slideDown 0.5s forwards;
}
