@layer components {
  .collapse {
    &-open .collapse-content,
    &:focus:not(.collapse-close) .collapse-content,
    &:not(.collapse-close) input[type='checkbox']:checked ~ .collapse-content {
      @apply max-h-fit;
    }
  }

  .collapse-text:not(.collapse-close)
    input[type='checkbox']:checked
    ~ .collapse-title:after {
    content: 'close';
  }

  .collapse-text .collapse-title:after {
    content: 'open';
    position: absolute;
    display: block;
    transition-property: all;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    right: 1.4rem;
    pointer-events: none;
    text-transform: uppercase;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 700;
  }

  .collapse-reverse {
    .collapse-title,
    input[type='checkbox'] {
      @apply row-start-3;
    }

    &.collapse {
      &-open .collapse-content,
      &:focus:not(.collapse-close) .collapse-content,
      &:not(.collapse-close)
        input[type='checkbox']:checked
        ~ .collapse-content {
        @apply pb-0 pt-4;
      }
    }
  }

  .collapse-plus {
    .collapse-toggle {
      @apply relative z-[1];
    }
    .collapse-title {
      @apply relative;
    }
    .collapse-title:after {
      @apply h-auto top-1/2 -translate-y-1/2;
    }
  }

  .table.table-wrapped :where(th, td) {
    white-space: normal;
    word-break: break-word;
  }
}
