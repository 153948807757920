@import "@fontsource/montserrat-alternates/400.css";
@import "@fontsource/montserrat-alternates/600.css";
@import "@fontsource/montserrat-alternates/700.css";
@import "@fontsource/montserrat-alternates/800.css";

@import "@fontsource/jost/400.css";
@import "@fontsource/jost/600.css";
@import "@fontsource/jost/700.css";
@import "@fontsource/jost/800.css";

/* @import url('https://fonts.cdnfonts.com/css/bukhari-script'); */

@font-face {
  font-family: 'Bukhari Script';
  src: url('/fonts/bukhari.ttf');
 }


@font-face {
  font-family: "Pizzicletta";
  src: local("Montserrat Alternates");
  font-weight: 400;
  font-style: normal;
}
